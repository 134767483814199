import http from 'http.js';
import moment from 'moment';
import * as Sentry from '@sentry/browser';

import {FEATURE_FLAGS_API} from 'apiEndpoints.js';

export async function checkSentryPreferences(sentryCallback) {
    /**
     *
     * This method accepts a callback function containing a call
     * to the Sentry API. Non-prod Sentry events should only be
     * sent within normal work hours (7am to 7pm AEST) to prevent events
     * generated from polling requests while the testing environment
     * is down overnight.
     *
     * Alternatively, if the `FRONTEND_SENTRY_EVENTS_AFTER_HOURS`
     * feature flag is enabled, then this window is ignored.
     *
     */

    // Always allow Sentry events on prod. We can't use `process.env.NODE_ENV`
    // because test environments have that value set to `production`, so we
    // can't differentiate between test and prod.
    const prodHosts = ['au.betterconsult.com', 'us.betterconsult.com'];
    if (prodHosts.includes(window.location.host)) {
        sentryCallback();
        return;
    }

    // Hard-coded window of 7am to 7pm where frontend Sentry events can be sent
    const startHour = 7;
    const endHour = 19;
    const currentHour = parseInt(moment().format('HH'));
    if (startHour <= currentHour && currentHour < endHour) {
        sentryCallback();
        return;
    }

    // For events sent out of work hours, check whether the
    // FRONTEND_SENTRY_EVENTS_AFTER_HOURS feature flag is enabled
    try {
        const featureFlags = await http.get({
            url: FEATURE_FLAGS_API,
        });
        const featureFlagEnabled = featureFlags.find(
            (ff) => ff.name === 'FRONTEND_SENTRY_EVENTS_AFTER_HOURS',
        )?.enabled;
        if (featureFlagEnabled) {
            sentryCallback();
        }
    } catch {
        // If the backend is down, don't send a Sentry event
        return;
    }
}

export function captureMessage({errorMessage, info = {}, tags = {}}) {
    checkSentryPreferences(() => {
        Sentry.withScope((scope) => {
            Object.entries(info).forEach(([key, val]) =>
                scope.setExtra(key, val),
            );
            Object.entries(tags).forEach(([key, val]) =>
                scope.setTag(key, val),
            );
            Sentry.captureMessage(errorMessage);
        });
    });
}

export function captureException({error, info = {}}) {
    checkSentryPreferences(() => {
        Sentry.withScope((scope) => {
            Object.entries(info).forEach(([key, val]) =>
                scope.setExtra(key, val),
            );
            Sentry.captureException(error);
        });
    });
}
