import query from 'query.js';

export default function(route, preservedQueryKeys) {
    const urlQueryData = query.parse();
    const preservedQueryData = preservedQueryKeys
        .filter((key) => urlQueryData.hasOwnProperty(key))
        .reduce((acc, key) => ({...acc, [key]: urlQueryData[key]}), {});
    const [path, search] = route.split('?');
    const routeQueryData = search ? query.parse(search) : {};
    const queryString = query.buildQueryString(
        {
            ...preservedQueryData,
            ...routeQueryData,
        },
        {fullPath: false},
    );
    return `${path}${queryString}`;
}
