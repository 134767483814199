import React from 'react';
import PropTypes from 'prop-types';

import iconPaths from 'iconPaths.js';

export default class Icon extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
    };

    getIconPath() {
        try {
            const {name} = this.props;
            const {children, viewBox, width, height, fill} = iconPaths[
                name
            ].svg.props;
            return (
                <svg
                    aria-hidden="true"
                    focusable="false"
                    viewBox={viewBox}
                    {...(width ? {width} : {})}
                    {...(height ? {height} : {})}
                    fill={fill !== undefined ? fill : 'none'}
                >
                    {children}
                </svg>
            );
        } catch (ex) {
            // eslint-disable-next-line no-console
            console.warn(`Missing icon for "${this.props.name}"`);
            return (
                <svg viewBox="0 0 24 24">
                    <rect fill="red" height="24" width="24" x="0" y="0" />
                </svg>
            );
        }
    }

    render() {
        return this.getIconPath();
    }
}
