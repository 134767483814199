export default function deduplicate(array, keyGetter = (item) => item) {
    const keyedItems = {};
    const orderPreservedArray = [];
    for (const item of array) {
        const key = keyGetter(item);
        if (typeof keyedItems[key] === 'undefined') {
            keyedItems[key] = item;
            // must also be pushed to an array as hashmaps are unordered
            orderPreservedArray.push(item);
        }
    }
    return orderPreservedArray;
}
