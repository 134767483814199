import PropTypes from 'prop-types';

export const genericQuestionPropTypes = {
    active: PropTypes.bool.isRequired,
    completed: PropTypes.bool.isRequired,
    helper: PropTypes.object,
    helperOpenByDefault: PropTypes.bool,
    isRenderedStep: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    reloadAction: PropTypes.func.isRequired,
    shouldReload: PropTypes.bool.isRequired,
    previouslyActive: PropTypes.bool.isRequired,
    subTitle: PropTypes.string,
    subTitleCapitalisation: PropTypes.oneOf(['title', 'sentence']),
    title: PropTypes.string,
    undo: PropTypes.bool.isRequired,
    undoable: PropTypes.bool.isRequired,
};
