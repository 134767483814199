import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, injectIntl} from 'react-intl';

const messages = defineMessages({
    continueButtonText: {
        id: 'questions.AnswerSubmitButton.continueButtonText',
        defaultMessage: 'continueButtonText',
    },
});

export class AnswerSubmitButton extends React.Component {
    static propTypes = {
        additionalClass: PropTypes.string,
        buttonNode: PropTypes.func,
        buttonText: PropTypes.string,
        dataTestId: PropTypes.string,
        isDisabled: PropTypes.bool,
        isSecondary: PropTypes.bool,
        submitAnswers: PropTypes.func.isRequired,
    };

    render() {
        const intl = this.props.intl;
        let classList = 'cta';
        if (this.props.isSecondary) {
            classList += ' cta-secondary';
        }
        return (
            <button
                className={this.props.additionalClass}
                data-test-id={this.props.dataTestId}
                disabled={this.props.isDisabled}
                onClick={this.props.submitAnswers}
                ref={this.props.buttonNode}
                type="submit"
            >
                <span className={classList}>
                    {this.props.buttonText ||
                        intl.formatMessage(messages.continueButtonText)}
                </span>
            </button>
        );
    }
}

export default injectIntl(AnswerSubmitButton);
