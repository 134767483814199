export default async function() {
    if (!window.Intl) {
        await Promise.all([
            import(/* webpackChunkName: "polyfill-intl" */ 'intl'),
            /* eslint-disable-next-line max-len */
            import(
                /* webpackChunkName: "polyfill-intl" */ 'intl/locale-data/jsonp/en.js'
            ),
        ]);
    }
}
