export function capitaliseFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toTitleCase(str) {
    if (typeof str !== 'string') {
        return str;
    }
    return str.trim().replace(/\w\S*/g, capitaliseFirstLetter);
}

export function toSentenceCase(str) {
    if (typeof str !== 'string') {
        return str;
    }
    return capitaliseFirstLetter(str.trim());
}

export default function capitalise(str, type) {
    switch (type) {
        case 'title': {
            return toTitleCase(str);
        }
        case 'sentence': {
            return toSentenceCase(str);
        }
        default: {
            return str;
        }
    }
}
