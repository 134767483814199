function isMergeable(obj) {
    const stringValue = Object.prototype.toString.call(obj);
    const isSpecial =
        stringValue === '[object RegExp]' ||
        stringValue === '[object Date]' ||
        stringValue === '[object Function]' ||
        stringValue === '[object Array]';
    return !!obj && typeof obj === 'object' && !isSpecial;
}

export function deepEqual(obj1, obj2) {
    const type1 = Object.prototype.toString.call(obj1);
    const type2 = Object.prototype.toString.call(obj2);
    if (type1 === type2) {
        const isObj1 = isMergeable(obj1) || Array.isArray(obj1);
        const isObj2 = isMergeable(obj2) || Array.isArray(obj2);
        if (isObj1 && isObj2) {
            if (Object.keys(obj1).length === Object.keys(obj2).length) {
                return Object.entries(obj2).every(
                    ([key2, value2]) =>
                        obj1.hasOwnProperty(key2) &&
                        deepEqual(obj1[key2], value2),
                );
            }
        } else {
            return obj1 === obj2;
        }
    }
    return false;
}

export function deepMerge(obj1, obj2) {
    for (const [key, value] of Object.entries(obj2)) {
        if (!obj1.hasOwnProperty(key)) {
            if (isMergeable(value)) {
                obj1[key] = deepClone(value);
            } else {
                obj1[key] = value;
            }
        }
        if (isMergeable(obj1[key]) && isMergeable(value)) {
            deepMerge(obj1[key], value);
        } else {
            if (isMergeable(value)) {
                obj1[key] = deepClone(value);
            } else {
                obj1[key] = value;
            }
        }
    }
}

export function deepClone(obj) {
    const isArray = Array.isArray(obj);
    let newObj = obj;
    if (isArray) {
        newObj = [];
        for (const item of obj) {
            let newItem = item;
            if (isMergeable(item) || Array.isArray(item)) {
                newItem = deepClone(item);
            }
            newObj.push(newItem);
        }
    } else if (isMergeable(obj)) {
        newObj = {};
        for (const [key, value] of Object.entries(obj)) {
            let newValue = value;
            if (isMergeable(value) || Array.isArray(value)) {
                newValue = deepClone(value);
            }
            newObj[key] = newValue;
        }
    }
    return newObj;
}

export default function deepMergeAll(...objects) {
    const obj = {};
    for (const object of objects) {
        if (!isMergeable(object)) {
            // eslint-disable-next-line no-console
            console.error(`'${object}' is not mergeable`);
            throw new Error(`'${object}' is not mergeable`);
        }
        deepMerge(obj, object);
    }
    return obj;
}
