export default function reverse(routes, name, path = '') {
    for (const route of routes) {
        if (route?.name === name) {
            return `${path}${route.react}`;
        }
        if (route?.routes) {
            const foundRoute = reverse(
                route.routes,
                name,
                `${path}${route.react}`,
            );
            if (foundRoute) {
                return foundRoute;
            }
        }
    }
    if (!path) {
        throw new Error(`Cannot find route with name '${name}'`);
    }
}
