import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import ContentHelper from 'better_consult/components/ContentHelper.js';
import autobind from 'common/decorators/autobind.js';
import throttle from 'common/throttle.js';
import ScrollToButton from 'core/components/ScrollToButton.js';
import events from 'events.js';
import capitalise from 'utils/capitalise.js';
import Icon from 'utils/components/Icon.js';
import {genericQuestionPropTypes} from 'utils/propTypesShapes.js';

const messages = defineMessages({
    edit: {
        id: 'questions.Question.edit',
        defaultMessage: 'edit',
    },
    defaultNoAnswerText: {
        id: 'questions.Question.defaultNoAnswerText',
        defaultMessage: 'defaultNoAnswerText',
    },
});

export class Question extends React.Component {
    static propTypes = {
        ...genericQuestionPropTypes,
        additionalClass: PropTypes.string,
        disableScrollIntoView: PropTypes.bool,
        handleEnter: PropTypes.func,
        setQuestionRef: PropTypes.func,
        undoAction: PropTypes.func,
        undoText: PropTypes.string,
        updateButtonPinnedState: PropTypes.func,
    };

    static defaultProps = {
        disableScrollIntoView: false,
        helperOpenByDefault: false,
        loading: false,
        subTitleCapitalisation: 'sentence',
    };

    constructor(props) {
        super(props);
        this.nodeRef = null;
        this.throttleScrolling = throttle(this.handleScrolling, 250);
        this.throttleResizing = throttle(this.handleResizing, 250);
        this.hasAnswersOutOfView = false;
        this.hasHitBottomOfView = false;
        this.nodeOffsetTopPosition = 0;

        this.state = {
            hasBeenPainted: false,
            isUndone: false,
            scrollBtnVisible: false,
        };
    }

    async reloadQuestion() {
        if (this.props.shouldReload) {
            await this.props.reloadAction();
            if (this.props.active || this.props.isRenderedStep) {
                this.setState({
                    hasBeenPainted: true,
                });
            }
        }
    }

    async componentDidMount() {
        this.scrollQuestionIntoView();
        events.listen(window, 'keydown', this.handleKey);
        events.listen(window, 'scroll', this.throttleScrolling);
        events.listen(window, 'resize', this.throttleResizing);
        await this.reloadQuestion();
    }

    componentWillUnmount() {
        events.unlisten(window, 'keydown', this.handleKey);
        events.unlisten(window, 'scroll', this.throttleScrolling);
        events.unlisten(window, 'resize', this.throttleResizing);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.undo && this.props.undo && this.props.completed) {
            this.props.undoAction();
        }

        if (!this.props.loading && this.props.active) {
            // handle scrolling for undo or submit
            if (prevProps.loading || !prevProps.active) {
                this.scrollQuestionIntoView();
            }
        }

        if (!prevProps.active && this.props.active) {
            this.hasAnswersOutOfView =
                this.nodeRef.offsetHeight > window.innerHeight;
            if (prevProps.completed) {
                this.setState({isUndone: true});
            }
        }
        if (this.state.isUndone && !this.props.active) {
            this.setState({isUndone: false});
        }
    }

    @autobind
    scrollQuestionIntoView(behavior = 'auto') {
        if (this.props.disableScrollIntoView) {
            return;
        }
        window.requestAnimationFrame(() => {
            if (this.props.active && this.nodeRef) {
                this.nodeRef.scrollIntoView({block: 'start', behavior});
                this.setState({
                    hasBeenPainted: true,
                });
            }
        });
    }

    @autobind
    handleKey(event) {
        if (this.props.loading) {
            return;
        }

        if (
            this.props.active &&
            event.key === 'Enter' &&
            this.props.handleEnter
        ) {
            const focusedElement = document.activeElement;
            const tagName = focusedElement.tagName.toLowerCase();

            const questionHasFocus =
                this.nodeRef.contains(focusedElement) &&
                (tagName === 'input' || tagName === 'select');

            if (questionHasFocus || tagName === 'body') {
                event.preventDefault();
                this.props.handleEnter(event);
            }
        }
    }

    @autobind
    handleScrolling() {
        if (this.props.active && this.nodeRef) {
            const nodeOffsetTop = this.nodeRef.offsetTop;
            // handle pinning submit button
            if (
                this.props.updateButtonPinnedState &&
                this.hasAnswersOutOfView
            ) {
                if (!this.hasHitBottomOfView) {
                    this.hasHitBottomOfView =
                        window.innerHeight +
                            Math.ceil(window.pageYOffset + 1) >=
                        document.body.offsetHeight;
                }
                const isCurrentQuestionInView =
                    window.scrollY >= nodeOffsetTop;
                const shouldPinSubmitButton =
                    this.hasHitBottomOfView && isCurrentQuestionInView;
                this.props.updateButtonPinnedState(shouldPinSubmitButton);
            }
            // handle showing scroll button
            const scrollBtnVisible =
                nodeOffsetTop >= window.scrollY + window.innerHeight;
            this.setState({scrollBtnVisible});
        }
    }

    @autobind
    handleResizing() {
        if (this.props.active && this.nodeRef) {
            const nodeOffsetTopPosition = this.nodeRef.offsetTop;
            if (nodeOffsetTopPosition !== this.nodeOffsetTopPosition) {
                this.scrollQuestionIntoView();
                this.nodeOffsetTopPosition = nodeOffsetTopPosition;
            }
        }
    }

    renderTitle(dataTestIdPrefix) {
        if (this.props.title) {
            const title = this.props.completed
                ? this.truncateText(this.props.title, 150)
                : this.props.title;
            return (
                <h2
                    className="title"
                    data-test-id={`${dataTestIdPrefix}-title`}
                >
                    {this.renderSubTitle(dataTestIdPrefix)}
                    {title}
                </h2>
            );
        }
        return null;
    }

    renderSubTitle(dataTestIdPrefix) {
        if (this.props.subTitle) {
            return (
                <span
                    className="question-prefix"
                    data-test-id={`${dataTestIdPrefix}-subtitle`}
                >
                    {capitalise(
                        this.props.subTitle,
                        this.props.subTitleCapitalisation,
                    )}
                </span>
            );
        }
    }

    truncateText(text, limit) {
        if (!text || typeof text !== 'string') {
            text = '';
        } else if (text.length > limit) {
            text = text.slice(0, limit);
            text += '...';
        }
        return text;
    }

    renderUndoText(isSubmitted, dataTestIdPrefix) {
        if (isSubmitted && this.props.undoText) {
            let undoTextClassList = 'selection';
            if (this.props.undoText && !this.props.undoText.match(/[a-z]/i)) {
                // no alpha characters
                undoTextClassList += ' number';
            }
            return (
                <button
                    className={undoTextClassList}
                    data-test-id={`${dataTestIdPrefix}-button`}
                    disabled={!this.props.previouslyActive}
                    onClick={this.props.undoAction}
                    type="button"
                >
                    <p data-test-id={`${dataTestIdPrefix}-answer`}>
                        {this.truncateText(this.props.undoText, 150)}
                    </p>
                    <span>
                        {this.props.intl.formatMessage(messages.edit)}
                        <Icon name="IconEdit" />
                    </span>
                </button>
            );
        }
        return null;
    }

    render() {
        if (
            this.props.active ||
            (this.props.completed && this.props.undoable) ||
            this.props.loading
        ) {
            let classList = 'question';
            const submitted = this.props.completed || this.props.loading;
            if (this.props.completed) {
                classList += ' answered';
            } else if (this.props.active) {
                classList += ' current';
            }
            if (this.state.hasBeenPainted) {
                classList += ' viewed';
            }
            if (this.props.additionalClass && !this.props.completed) {
                classList += ` ${this.props.additionalClass}`;
            }
            if (this.state.isUndone) {
                classList += ' undone';
            }

            const dataTestId = this.props.active
                ? 'active-step'
                : 'submitted-step';

            return (
                // TODO update fragment wrapper with
                // short syntax once fully supported
                // https://reactjs.org/docs/fragments.html#short-syntax
                <React.Fragment>
                    {!submitted && this.state.scrollBtnVisible && (
                        <ScrollToButton
                            scrollTo={this.scrollQuestionIntoView}
                        />
                    )}
                    <section
                        aria-busy={this.props.loading}
                        className={classList}
                        data-test-id={dataTestId}
                        ref={(node) => {
                            this.nodeRef = node;
                            if (this.props.setQuestionRef) {
                                this.props.setQuestionRef(node);
                            }
                        }}
                    >
                        {this.renderTitle(dataTestId)}
                        {this.props.helper && !submitted && (
                            <ContentHelper
                                helperContent={this.props.helper}
                                openByDefault={this.props.helperOpenByDefault}
                            />
                        )}
                        {(!submitted || (submitted && !this.props.undoText)) &&
                            this.props.children}
                        {this.renderUndoText(submitted, dataTestId)}
                    </section>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}

export function genericProps(props) {
    return {
        active: props.active,
        completed: props.completed,
        helper: props.helper,
        helperOpenByDefault: props.helperOpenByDefault,
        isRenderedStep: props.isRenderedStep,
        loading: props.loading,
        previouslyActive: props.previouslyActive,
        shouldReload: props.shouldReload,
        subTitle: props.subTitle,
        subTitleCapitalisation: props.subTitleCapitalisation,
        title: props.title,
        undo: props.undo,
        undoable: props.undoable,
    };
}

export function limitAnswers(answers) {
    if (!answers) {
        return;
    }
    return answers.length > 15 ? answers.slice(0, 15) : answers;
}

export default injectIntl(Question);
